import React from 'react';
import Image from 'gatsby-image';
import CountUp from 'react-countup';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Favicon from '../../images/favicon.ico';
import { Link } from 'gatsby';
const AVANTAGES = [
  {
    title: 'Évaluez Vos Besoins de Sécurité :',
    content:
      'Tout d’abord, identifiez les actifs que vous souhaitez protéger. Qu’il s’agisse de documents confidentiels, d’objets de valeur ou de souvenirs précieux, faites l’inventaire des objets contenus dans votre coffre-fort.',
  },
  {
    title: 'Choisissez la Taille et la Capacité Adequates :',
    content:
      'Choisissez un coffre-fort dont la taille et la capacité correspondent à vos besoins. Il doit être suffisamment grand pour contenir toutes vos biens.',
  },
  {
    title: 'Vérifiez les Certifications de Sécurité :',
    content: 'Recherchez un coffre-fort certifié résistant au feu et au vol.',
  },
  {
    title: "Assurez-vous d'une Fixation Solide ",
    content:
      'Pour plus de sécurité, assurez-vous que votre coffre-fort est correctement fixé au sol ou au mur. Il sera alors plus difficile pour les voleurs de l’enlever.',
  },
];
function CoffreFort({ data }) {
  const { SuperSerrurierVan, CoffreFortGeneve, ReparationCoffre } = data;
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          Services de Coffre-Fort | Vente, Ouverture et Réparation de
          Coffres-Forts
        </title>
        <meta
          name="description"
          content="Sécurisez vos biens avec nos services de coffre-fort résidentiel et commercial. Vente, ouverture et réparation de coffres-forts assurées par notre équipe d'experts. Obtenez un devis gratuit en un clic ou contactez-nous au +4179 130 47 68."
        />
        <meta name="keywords" content="coffre fort" />
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <main className="flex justify-between gap-4 lg:gap-8 mt-16 sm:mt-24 flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <h1 className="text-textcolor text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
              Service de Coffre Fort Professionnel : La Garantie de Sécurité
              Absolue
            </h1>
            <div className="w-full lg:hidden">
              <Image
                fluid={SuperSerrurierVan.childImageSharp.fluid}
                alt="Voiture de service utulisé pour les intervention de coffre fort"
              />
            </div>
            <p className="mt-4 text-lg text-gray-600">
              Qu'il s'agisse de protéger vos objets de valeur, d'accéder à votre
              coffre-fort existant ou d'effectuer des réparations en cas de
              problème, notre équipe expérimentée vous fournira une solution de
              sécurité complète. Nous proposons des services de vente,
              d'ouverture et de réparation en toute sécurité pour répondre à vos
              besoins en matière de sécurité. Nos experts connaissent tous les
              types de coffres-forts, des modèles standards aux plus complexes.
              Vous pouvez compter sur notre engagement envers votre sécurité et
              la protection de vos actifs. Choisissez nos services de sécurité
              fiables et professionnels pour avoir l’esprit tranquille.
            </p>
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="w-full hidden lg:block lg:w-1/2">
            <Image
              fluid={SuperSerrurierVan.childImageSharp.fluid}
              alt="Voiture de service utulisé pour les intervention de coffre fort"
            />
          </div>
        </main>
        <div className="w-screen absolute left-0 mt-24 py-8 lg:py-14 h-fit bg-primarycolor">
          <dl className="text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Clients par an
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={3000} duration={2.75} prefix="+ " />
              </dd>
            </div>{' '}
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Temps d'intervention
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp
                  end={20}
                  start={60}
                  duration={2.75}
                  suffix=" Minutes"
                />
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                De satisfaction
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={98} duration={2.75} suffix="%" />
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-128 lg:mt-96 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Guide Pratique pour Choisir le Coffre-Fort Idéal
              </h2>
              <Image
                fluid={CoffreFortGeneve.childImageSharp.fluid}
                alt="un coffre fort"
                className={'my-4 lg:hidden rounded-lg'}
              />
            </div>
            <div className="mt-4 lg:mt-12">
              {AVANTAGES.map((e) => {
                return (
                  <div className="flex mt-4">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-8 w-8 text-green-700">
                        <svg
                          className=" h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4 text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-600">
                        <b>{e.title}</b> {e.content}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <div className="bg-white p-8 shadow-lg rounded-lg z-10">
              {' '}
              <Image
                fluid={CoffreFortGeneve.childImageSharp.fluid}
                alt="un coffre fort"
                className={'hidden lg:block rounded-lg'}
              />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-24 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={ReparationCoffre.childImageSharp.fluid}
              alt="Les types de serrures"
              className={'hidden lg:block'}
            />
          </div>{' '}
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Interventions d'Ouverture et de Réparation de Coffres-forts à
                Domicile
              </h2>
              <Image
                fluid={ReparationCoffre.childImageSharp.fluid}
                alt="Les types de serrures"
                className={'my-8 lg:hidden'}
              />
            </div>
            <p className="mt-4 lg:mt-8 list-disc space-y-2 text-lg leading-6 font-medium text-gray-600">
              Vous avez besoin d'une assistance immédiate pour ouvrir ou réparer
              votre coffre-fort à domicile ? Notre entreprise est là pour vous,
              24 heures sur 24, 7 jours sur 7. En tant que serruriers
              professionnels et certifiés, nous garantissons une réponse rapide
              en moins de 20 minutes.
              <br /> La sécurité de vos biens est notre priorité, et notre
              équipe expérimentée est prête à intervenir rapidement pour
              résoudre vos problèmes de coffre-fort, que ce soit en urgence ou
              selon votre convenance. Faites confiance à notre expertise pour
              des services de haute qualité à tout moment de la journée ou de la
              nuit.
            </p>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-32">
          <h2 className="text-primarycolor text-2xl font-extrabold text-center tracking-tight sm:text-5xl">
            Nos Zones d’interventions
          </h2>
          <div className="pt-12 grid grid-cols-2 content-center gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-5">
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/valais"
                className="zone-area duration-150 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.valais.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Valais"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Valais
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/serrurier-geneve"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.geneve.childImageSharp.fixed}
                  className="h-32 max-w-32 max-h-32 mx-auto conton"
                  alt="Genève"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Genève
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/neuchatel"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.neuchatel.childImageSharp.fixed}
                  className="h-32 max-w-32  max-h-32 conton"
                  alt="Neuchatel"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Neuchatel
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8 text-gray-600 hover:text-primarycolor">
              <Link
                to="/vaud"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.vaud.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Vaud"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Vaud
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-14 text-gray-600 hover:text-primarycolor">
              <Link
                to="/fribourg"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.fribourg.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Fribourg"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Fribourg
                </h3>
              </Link>
            </div>
          </div>
          <div className="mx-auto flex justify-center pb-12">
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="  ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CoffreFort;

export const query = graphql`
  query coffre_fort_page {
    SuperSerrurierVan: file(relativePath: { eq: "SuperSerrurierVan.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CoffreFortGeneve: file(relativePath: { eq: "coffre-fort.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ReparationCoffre: file(relativePath: { eq: "reparation-coffre-fort.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    fribourg: file(relativePath: { eq: "Fribourg.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    geneve: file(relativePath: { eq: "Geneve.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    valais: file(relativePath: { eq: "Valais.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    vaud: file(relativePath: { eq: "Vaud.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
